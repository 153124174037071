import React, { useState, useEffect } from "react"
import { useAccount } from "wagmi"
import { useNavigate } from "react-router"
import cls from "./Header.module.scss"
import { classNames } from "~/tools/classNames"
import ConnectButton from "../../widgets/ConnectWidgets/ConnectButton/ConnectButton"
import { ampli, track } from "../../../helpers/amplitude"
import { toElement } from "../../../helpers/helpers"
import { Button } from "../Button/Button"

export default function Header(props) {
    const { className, refs } = props
    const { EcoRef, TokenRef, RoadRef, NFTRef, HeroRef, MintRef } = refs
    const [scrolled, setScrolled] = useState(false)
    const navigate = useNavigate()

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 40) {
                setScrolled(true)
            } else {
                setScrolled(false)
            }
        }
        window.addEventListener("scroll", handleScroll)
        return () => {
            window.removeEventListener("scroll", handleScroll)
        }
    }, [])

    const { address } = useAccount()

    return (
        <header className={classNames(cls.header, { [cls.scrolled]: scrolled }, [className])} id="header">
            <div className={cls.container}>
                <div className={cls.logo} onClick={() => toElement(HeroRef)}>
                    ToON.ORG
                </div>
                <div className={cls.listWrapper}>
                    <ul className={cls.list}>
                        <li
                            onClick={() => {
                                track(ampli.navClick, {
                                    section: "About",
                                    version: localStorage.getItem("userMainGroup")
                                })
                                toElement(HeroRef)
                            }}
                        >
                            About
                        </li>
                        <li
                            onClick={() => {
                                track(ampli.navClick, {
                                    section: "Ecosystem",
                                    version: localStorage.getItem("userMainGroup")
                                })
                                toElement(EcoRef)
                            }}
                        >
                            ToON Ecosystem
                        </li>
                        <li
                            onClick={() => {
                                track(ampli.navClick, {
                                    section: "Tokenomics",
                                    version: localStorage.getItem("userMainGroup")
                                })
                                toElement(TokenRef)
                            }}
                        >
                            Tokenomics
                        </li>
                        <li
                            onClick={() => {
                                track(ampli.navClick, {
                                    section: "Voucher",
                                    version: localStorage.getItem("userMainGroup")
                                })
                                toElement(NFTRef)
                            }}
                        >
                            Reserve $TOON
                        </li>
                        <li
                            onClick={() => {
                                track(ampli.navClick, {
                                    section: "Roadmap",
                                    version: localStorage.getItem("userMainGroup")
                                })

                                toElement(RoadRef)
                            }}
                        >
                            Roadmap
                        </li>
                    </ul>
                </div>
                {/*  <Button header onClick={() => navigate("customize")}>
                    Customize
                </Button> */}
                <ConnectButton
                    header
                    headerStyle
                    MintRef={MintRef}
                    action={() => {
                        /* toElement(NFTRef) */
                        track(ampli.walletHeader, { address, version: localStorage.getItem("userMainGroup") })
                    }}
                />
            </div>
        </header>
    )
}
