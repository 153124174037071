import Ethereum from "@/mainnet.webp"
import Polygon from "@/polygon.webp"
import BNB from "@/bnb.webp"
import Base from "@/base.webp"
import Op from "@/op.webp"

import chainImg from "@/defaultChain.webp"
import { ampli, track } from "./amplitude"

export const getChainImg = (chain) => {
    switch (chain.id) {
        case 1:
            return Ethereum
        /* case 10:
			return Optimism */
        case 137:
            return Polygon
        case 56:
            return BNB
        case 8453:
            return Base
        case 10:
            return Op
        default:
            return chainImg
    }
}

export const preventScroll = () => (document.documentElement.style.overflow = "hidden")
export const enableScroll = () => (document.documentElement.style.overflow = "inherit")

export const toElement = (ref) => {
    if (!ref.current) {
        return
    }
    const elementPosition = ref.current.getBoundingClientRect().top + window.scrollY - 100

    window.scroll({
        top: elementPosition,
        behavior: "smooth"
    })
}

export const toNFT = () => {
    const NFT = document.getElementById("mintID")
    if (!NFT) {
        console.error("Element not found.")
        return
    }
    const elementPosition = NFT.getBoundingClientRect().top + window.scrollY - 100
    window.scroll({
        top: elementPosition,
        behavior: "smooth"
    })
}

export const onPageLoad = () => {
    const eventParams = {
        full: window.location.href,
        host: window.location.host,
        path: window.location.path,
        queryString: window.location.search,
        version: localStorage.getItem("userMainGroup")
    }

    track(ampli.pageLoad, eventParams)
}

export function transformToDownloadUrl(imageUrl) {
    if (!window.appConfig) {
        window.appConfig = { proxy: "https://storage.photo-cdn.net" }
    }
    const appUrl = new URL(window.appConfig.proxy)
    const url = new URL(imageUrl)
    const hostname = url.hostname.toLowerCase()

    if (hostname === "temp-images.ws.pho.to") {
        url.protocol = appUrl.protocol
        url.host = appUrl.host
        url.pathname = `/download/temp-images.ws.pho.to${url.pathname}`
    }

    if (hostname === "worker-images.ws.pho.to") {
        url.protocol = appUrl.protocol
        url.host = appUrl.host
        url.pathname = `/download/worker-images.ws.pho.to${url.pathname}`
    }

    if (hostname.match(/worker-images-[2-9].ws.pho.to/)) {
        url.protocol = appUrl.protocol
        url.host = appUrl.host
        url.pathname = `/download/${hostname}${url.pathname}`
    }

    if (hostname === "webview-app.ws.pho.to") {
        url.protocol = appUrl.protocol
        url.host = appUrl.host
        url.pathname = `/download${url.pathname}`
    }

    if (hostname === "pw.test.photo-cdn.net") {
        url.protocol = appUrl.protocol
        url.host = appUrl.host
        url.pathname = `/download/pw.test.photo-cdn.net${url.pathname}`
    }

    if (hostname === "client-upload-proxy") {
        url.protocol = appUrl.protocol
        url.host = appUrl.host
        url.pathname = `/download/uploads/npp/creatives${url.pathname}`
    }

    return url.toString()
}

export const modifyUrl = (url) => {
    return `${url.split(".jpeg")[0]}_no_watermark.jpeg`
}
