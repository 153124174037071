import React, { createContext, useContext, useReducer, useMemo } from "react"

const GlobalContext = createContext()

export const useGlobal = () => useContext(GlobalContext)

const initialState = {
    taskID: "",
    address: "",
    shareID: "",
    imageURL: "",
    shareURL: "",
    nftURL: "",
    creatives: [],
    signed: false,
    nftMinted: false
}

const globalReducer = (state, action) => {
    switch (action.type) {
        case "CLEAR_GLOBAL_STATE":
            return { ...initialState }
        case "UPDATE_TASK_ID":
            return { ...state, taskID: action.payload }
        case "UPDATE_SIGN":
            return { ...state, signed: true }
        case "UPDATE_MINTED":
            return { ...state, nftMinted: true }
        case "UPDATE_CREATIVES":
            return { ...state, creatives: action.payload }
        case "UPDATE_ADDRESS":
            return { ...state, address: action.payload }
        case "UPDATE_TEMPLATE":
            return { ...state, shareID: action.payload }
        case "UPDATE_IMAGE_URL":
            return { ...state, imageURL: action.payload }
        case "UPDATE_SHARE_URL":
            return { ...state, shareURL: action.payload }
        case "UPDATE_NFT_URL":
            return { ...state, nftURL: action.payload }
        default:
            return state
    }
}

export const GlobalProvider = ({ children }) => {
    const [state, dispatch] = useReducer(globalReducer, initialState)

    const value = useMemo(
        () => ({
            globalState: state,
            clearGlobalState: () => dispatch({ type: "CLEAR_GLOBAL_STATE" }),
            updateTaskID: (newTaskID) => dispatch({ type: "UPDATE_TASK_ID", payload: newTaskID }),
            updateSign: () => dispatch({ type: "UPDATE_SIGN" }),
            updateMinted: () => dispatch({ type: "UPDATE_MINTED" }),
            updateCreatives: (creatives) => dispatch({ type: "UPDATE_CREATIVES", payload: creatives }),
            updateAddress: (newAddress) => dispatch({ type: "UPDATE_ADDRESS", payload: newAddress }),
            updateShareID: (newTemplateID) => dispatch({ type: "UPDATE_TEMPLATE", payload: newTemplateID }),
            updateImageUrl: (newimageUrl) => dispatch({ type: "UPDATE_IMAGE_URL", payload: newimageUrl }),
            updateShareUrl: (newShareUrl) => dispatch({ type: "UPDATE_SHARE_URL", payload: newShareUrl }),
            updateNFTUrl: (newNFTUrl) => dispatch({ type: "UPDATE_NFT_URL", payload: newNFTUrl })
        }),
        [state]
    )

    return <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
}
