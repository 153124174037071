import Ethereum from "@/mainnet.webp"
import Polygon from "@/polygon.webp"
import BNB from "@/bnb.webp"
import Base from "@/base.webp"
import Op from "@/op.webp"

import chainImg from "@/defaultChain.webp"
import { ampli, track } from "./amplitude"

export const getChainImg = (chain) => {
    switch (chain.id) {
        case 1:
            return Ethereum
        /* case 10:
			return Optimism */
        case 137:
            return Polygon
        case 56:
            return BNB
        case 8453:
            return Base
        case 10:
            return Op
        default:
            return chainImg
    }
}

export const preventScroll = () => (document.documentElement.style.overflow = "hidden")
export const enableScroll = () => (document.documentElement.style.overflow = "inherit")

export const toElement = (ref) => {
    if (!ref.current) {
        return
    }
    const elementPosition = ref.current.getBoundingClientRect().top + window.scrollY - 100

    window.scroll({
        top: elementPosition,
        behavior: "smooth"
    })
}

export const toNFT = () => {
    const NFT = document.getElementById("mintID")
    if (!NFT) {
        console.error("Element not found.")
        return
    }
    const elementPosition = NFT.getBoundingClientRect().top + window.scrollY - 100
    window.scroll({
        top: elementPosition,
        behavior: "smooth"
    })
}

export const onPageLoad = () => {
    const eventParams = {
        full: window.location.href,
        host: window.location.host,
        path: window.location.path,
        queryString: window.location.search,
        version: localStorage.getItem("userMainGroup")
    }

    track(ampli.pageLoad, eventParams)
}
