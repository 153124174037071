import React, { useCallback } from "react"
import { toast } from "react-toastify"
import Error from "@/UI/icons/error.svg"
import Wallet from "@/UI/icons/wallet.webp"

import { CHECK_WALLET, PURCHASE_ERRORS } from "../texts"
import { ampli, track } from "../amplitude"

const { 4001: cancelledMessage } = PURCHASE_ERRORS
const { 32000: fundsMessage } = PURCHASE_ERRORS
const generalMessage = PURCHASE_ERRORS.general

function getImage(chain) {
    switch (chain.id) {
        case 1:
            return "./assets/UI/icons/1.webp"
        case 56:
            return "./assets/UI/icons/56.webp"
        case 137:
            return "./assets/UI/icons/137.webp"
        case 8453:
            return "./assets/UI/icons/8453.webp"
        case 10:
            return "./assets/UI/icons/10.webp"
        /* case 42161:
			return "./assets/UI/icons/42161.webp" */
        default:
            return "./assets/UI/icons/default.webp"
    }
}

export const getErrorType = (error) => {
    if (
        error?.message.includes("cancelled") ||
        error?.message.includes("canceled") ||
        error?.message.includes("rejected")
    ) {
        return "cancelled"
    }
    if (error?.message.includes("funds") || error?.message.includes("total cost")) {
        return "insufficient funds"
    }
    return "other"
}

export function useToast() {
    const checkWallet = useCallback(() => {
        toast.info(CHECK_WALLET, {
            icon: () => <img src={Wallet} className="toastIcon" alt="icon" />,
            toastId: "check",
            autoClose: false,
            delay: 500,
            className: "checkWalletToast"
        })
    }, [])

    const chooseOption = useCallback(() => {
        toast.info("Please enter value or click the checkbox", {
            icon: () => <img src={Error} className="toastIcon" alt="icon" />,
            toastId: "choose",
            position: "top-center"
        })
    }, [])

    const mintError = useCallback((error) => {
        let message
        if (
            error?.message.includes("cancelled") ||
            error?.message.includes("canceled") ||
            error?.message.includes("rejected")
        ) {
            message = cancelledMessage
        } else if (error?.message.includes("funds") || error?.message.includes("total cost")) {
            message = fundsMessage
        } else {
            message = generalMessage
        }

        toast.error(message, {
            icon: () => <img src={Error} className="toastIcon" alt="icon" />,
            toastId: "error"
        })
    }, [])

    const chainSwitch = useCallback((chain) => {
        const imageSrc = getImage(chain)
        toast.success(
            () => (
                <>
                    Successfully connected to <b>{chain.name}</b> network!
                </>
            ),
            {
                icon: () => <img src={imageSrc} className="toastIcon" alt="icon" />,
                toastId: chain.name
            }
        )
    }, [])

    const dismissAll = () => toast.dismiss()

    const checkNFTError = useCallback(() => {
        toast.error("Verification failed. Please retry or reach out to support if the issue persists.", {
            icon: () => <img src={Error} className="toastIcon" alt="icon" />,
            toastId: "errorVerification"
        })
    }, [])

    const checkPhotoError = useCallback(() => {
        toast.error("Oops! Something went wrong with your image upload. Please try again.", {
            icon: () => <img src={Error} className="toastIcon" alt="icon" />,
            toastId: "errorCheckPhoto"
        })
    }, [])

    const checkGenerateError = useCallback(() => {
        toast.error("Woops! Looks like there's a small issue with your image upload. Please try again.", {
            icon: () => <img src={Error} className="toastIcon" alt="icon" />,
            toastId: "errorGenerate"
        })
    }, [])

    const submitTemplateError = useCallback(() => {
        toast.error("Woops! Something went wrong with submitting your look. Please try again.", {
            icon: () => <img src={Error} className="toastIcon" alt="icon" />,
            toastId: "errorSubmit"
        })
    }, [])

    return {
        submitTemplateError,
        checkGenerateError,
        checkPhotoError,
        checkNFTError,
        mintError,
        chainSwitch,
        dismissAll,
        checkWallet,
        chooseOption
    }
}
