import React, { useEffect, useRef, lazy, Suspense, useState, useCallback, useMemo } from "react"
import { Slide, ToastContainer } from "react-toastify"
import Hero from "../../widgets/MainPageWidgets/Hero/Hero.jsx"
import Loader from "../../shared/Loader/Loader.jsx"
import { mainconfig } from "../../../config"
import { ampli, track } from "../../../helpers/amplitude"
import { ShowModalButton } from "../../widgets/ShowModalButton/ShowModalButton"
import Header from "../../shared/Header/Header"
import { ToastCloseButton } from "../../shared/Toast/ToastCloseButton"
import { onPageLoad } from "../../../helpers/helpers"
import { SuccessModalContext } from "../../../providers/SuccessModalContext/SuccessModalContext"
import MintSuccessModal from "../../../../widgets/PremiumWidgets/MintSuccessModal/MintSuccessModal.jsx"

const Footer = lazy(() => import("../../shared/Footer/Footer.jsx"))
const Roadmap = lazy(() => import("../../widgets/MainPageWidgets/Roadmap/Roadmap.jsx"))
const Utils = lazy(() => import("../../widgets/MainPageWidgets/Utilities/Utilities.jsx"))
const Team = lazy(() => import("../../widgets/MainPageWidgets/Team/Team.jsx"))
const Ecosystem = lazy(() => import("../../widgets/MainPageWidgets/Ecosystem/Ecosystem.jsx"))
const Values = lazy(() => import("../../widgets/MainPageWidgets/Values/Values.jsx"))
const Tokenomic = lazy(() => import("../../widgets/MainPageWidgets/Tokenomic/Tokenomic.jsx"))
const Premium = lazy(() => import("../../widgets/MainPageWidgets/Premium/Premium"))

document.addEventListener("readystatechange", () => {
    if (document.readyState === "complete") {
        onPageLoad()
    }
})

document.addEventListener("DOMContentLoaded", function () {
    const reachedPlaces = []
    setInterval(() => {
        const scrollTop = window.pageYOffset
        const windowHeight = window.innerHeight
        const scrollPlaceElements = document.querySelectorAll("[data-scroll-place-id]")
        scrollPlaceElements.forEach((item) => {
            const placeId = item.dataset.scrollPlaceId
            const placeTop = item.offsetTop

            if (placeTop < scrollTop + windowHeight / 2) {
                if (reachedPlaces.indexOf(placeId) === -1) {
                    reachedPlaces.push(placeId)

                    /* track(ampli.pageScroll, { reachedPlaces: JSON.stringify(reachedPlaces) }) */
                    track(ampli.pageScroll, { reachedPlaces: placeId, version: localStorage.getItem("userMainGroup") })
                }
            }
        })
    }, 500)
})

function MainOLD() {
    /* ---------------------------- REVEAL ANIMATION ---------------------------- */
    const revealElements = useRef(null)
    const refs = {
        HeroRef: useRef(null),
        RoadRef: useRef(null),
        EcoRef: useRef(null),
        NFTRef: useRef(null),
        TokenRef: useRef(null),
        LinkRef: useRef(null),
        MintRef: useRef(null)
    }

    // eslint-disable-next-line consistent-return
    useEffect(() => {
        if (!mainconfig.isSafari) {
            const elements = revealElements.current.querySelectorAll("[data-reveal]")
            const options = {
                threshold: 0.1
            }
            const observer = new IntersectionObserver((entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add("animated")
                        observer.unobserve(entry.target)
                    }
                })
            }, options)
            elements.forEach((el) => observer.observe(el))

            return () => observer.disconnect()
        }
    }, [])

    const [showModalButton, setShowModalButton] = useState(false)
    const [showModal, setShowModal] = useState(false)

    const onCloseModal = useCallback(() => {
        setShowModal(false)
        track(ampli.successModalClose, { version: localStorage.getItem("userMainGroup") })
    }, [setShowModal])

    const SuccessModalContextValue = useMemo(
        () => ({
            showModalButton,
            setShowModalButton,
            showModal,
            setShowModal
        }),
        [showModalButton, setShowModalButton, showModal, setShowModal]
    )

    return (
        <SuccessModalContext.Provider value={SuccessModalContextValue}>
            <Header refs={refs} />
            <div id="content-wrap" className={mainconfig.isMobile ? "safari" : ""} ref={revealElements}>
                <main className="main-sections">
                    <Hero ref={refs.HeroRef} NFTRef={refs.NFTRef} />
                    <Suspense fallback={<Loader />}>
                        <Ecosystem ref={refs.EcoRef} LinkRef={refs.LinkRef} />
                    </Suspense>
                    <Suspense fallback={<Loader />}>
                        <Team />
                    </Suspense>
                    <Suspense fallback={<Loader />}>
                        <Values />
                    </Suspense>
                    <Suspense fallback={<Loader />}>
                        <Tokenomic ref={refs.TokenRef} />
                    </Suspense>
                    <Suspense fallback={<Loader />}>
                        <Utils />
                    </Suspense>
                    <Suspense fallback={<Loader />}>
                        <Premium ref={refs.NFTRef} MintRef={refs.MintRef} />
                    </Suspense>
                    <Suspense fallback={<Loader />}>
                        <Roadmap ref={refs.RoadRef} LinkRef={refs.LinkRef} />
                    </Suspense>
                </main>
                <Suspense fallback={null}>
                    <Footer />
                </Suspense>
                <ToastContainer
                    autoClose={6000}
                    className="customToast"
                    closeButton={ToastCloseButton}
                    position="bottom-right"
                    hideProgressBar
                    transition={Slide}
                    pauseOnFocusLoss={false}
                />
                {showModalButton && (
                    <ShowModalButton
                        onClick={() => {
                            track(ampli.modalButton)
                            setShowModal(true)
                        }}
                    />
                )}
                {showModal && (
                    <Suspense fallback={null}>
                        <MintSuccessModal isOpen={showModal} onClose={onCloseModal} />
                    </Suspense>
                )}
            </div>
        </SuccessModalContext.Provider>
    )
}

export default MainOLD
