import { useWeb3Modal } from "@web3modal/wagmi/react"
import React, { useState, useCallback } from "react"
import { useAccount, useDisconnect } from "wagmi"
import { Button, ButtonColor } from "~/components/shared/Button/Button"
import { toNFT } from "~/helpers/helpers"
import { supportedChains, mainconfig } from "~/config"
import ConnectModal from "../ConnectModal/ConnectModal"
import { useToast } from "~/helpers/hooks/useToast"
import { ampli, track } from "~/helpers/amplitude"
import { classNames } from "~/tools/classNames"
import cls from "./ConnectButton.module.scss"
import { ButtonSize } from "../../../shared/Button/Button"

function Account({ header }) {
    const { dismissAll } = useToast()
    return (
        <div>
            <w3m-account-button
                balance={false}
                onClick={() => {
                    dismissAll()
                }}
            />
        </div>
    )
}

function Chain() {
    const { dismissAll } = useToast()

    return <w3m-network-button onClick={() => dismissAll()} />
}

function ConnectButton(props) {
    const { action, connect, header, className, full, mint, headerStyle, black, noSolana } = props

    const mods = {
        [cls.connect]: connect,
        [cls.mint]: mint,
        [cls.black]: black
    }
    const { open } = useWeb3Modal()
    const { isConnected, chain } = useAccount()
    const { disconnectAsync } = useDisconnect()

    const { isMetaMask } = mainconfig
    const { isMobile } = mainconfig

    const handleConnect = async () => {
        await disconnectAsync()
        open()
    }

    const [connectModal, setConnectModal] = useState(false)
    const onCloseModal = useCallback(() => {
        setConnectModal(false)
        track(ampli.closeModal, {
            version: localStorage.getItem("userMainGroup")
        })
    }, [])
    return isConnected ? (
        <div {...props} className={`${cls.connected} ${header || headerStyle ? "" : cls.noHeader} ${className || ""}`}>
            {isMetaMask && header === false && supportedChains.includes(chain.id) && <Chain />}
            {!isMetaMask && header === false && supportedChains.includes(chain.id) && <Chain />}

            <Account header={header} />
        </div>
    ) : (
        <>
            <Button
                className={classNames(cls.ConnectButton, mods, [className])}
                header={header}
                size={full ? ButtonSize.full : null}
                onClick={() => {
                    if (action) {
                        action()
                    }
                    if (isMobile && !isMetaMask) {
                        setConnectModal(true)
                    } else {
                        handleConnect()
                    }
                }}
            >
                Connect Wallet
            </Button>
            {connectModal && <ConnectModal isOpen={connectModal} onClose={onCloseModal} noSolana={noSolana} />}
        </>
    )
}
export default React.memo(ConnectButton)
