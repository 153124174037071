/* eslint-disable react/no-unescaped-entities */
import React, { useContext, useEffect } from "react"
import { useWeb3Modal } from "@web3modal/wagmi/react"

import { useAccount, useDisconnect } from "wagmi"
import { Button } from "~/components/shared/Button/Button"
import { Modal } from "~/components/shared/Modal/Modal.jsx"
import cls from "./ConnectModal.module.scss"
import MM from "@/mm.svg"
import WC from "@/wc.svg"
import SolanaIcon from "@/UI/icons/solana-icon.svg"
import Ton from "@/UI/icons/ton.svg"

import { ampli, track } from "~/helpers/amplitude"
import { Links } from "~/helpers/texts"
import { Link } from "~/components/shared/Link/Link"
import { SolanaModalContext } from "../../../../providers/SolanaModalContext/SolanaModalContext"

function WCConnector(props) {
    const { open } = useWeb3Modal()
    const { onClose } = props
    return (
        <Button
            className={cls.modalBtn}
            onClick={() => {
                track(ampli.connectModal, {
                    connector: "WalletConnect",
                    version: localStorage.getItem("userMainGroup")
                })
                onClose()
                open()
            }}
        >
            <div className={cls.main}>
                <img src={WC} width="72" height="72" data-img alt="WalletConnect" />
                <div className={cls.texts}>
                    <h4>WalletConnect</h4>
                    <p>Use WalletConnect to connect</p>
                </div>
            </div>
            <Link arrow className={cls.link} href={false}>
                Connect
            </Link>
        </Button>
    )
}

function MMConnector() {
    return (
        <Button
            className={cls.modalBtn}
            onClick={() => {
                track(ampli.connectModal, { connector: "MetaMask", version: localStorage.getItem("userMainGroup") })
                window.open(Links.metamask, "_blank")
            }}
        >
            <div className={cls.main}>
                <img src={MM} width="72" height="72" data-img alt="MetaMask" />
                <div className={cls.texts}>
                    {" "}
                    <h4>MetaMask</h4>
                    <p>Connect to your MetaMask Wallet</p>
                </div>
            </div>
            <Link arrow className={cls.link} href={false}>
                Connect
            </Link>
        </Button>
    )
}

function SolanaConnector({ onClose }) {
    const { setIsSolanaModal } = useContext(SolanaModalContext)

    return (
        <Button
            className={cls.modalBtn}
            onClick={() => {
                track(ampli.connectModal, { connector: "Solana", version: localStorage.getItem("userMainGroup") })
                onClose()
                setIsSolanaModal(true)
            }}
        >
            <div className={cls.main}>
                <div className={cls.icons}>
                    <img src={SolanaIcon} width="40" height="40" alt="Solana" className={cls.sol} />
                    <img src={Ton} width="40" height="40" alt="Solana" className={cls.ton} />
                </div>
                <div className={cls.texts}>
                    <h4>Solana, TON</h4>
                    <p> </p>
                </div>
            </div>
            <Link arrow className={cls.link} href={false}>
                Connect
            </Link>
        </Button>
    )
}

function ConnectModal(props) {
    const { isOpen, onClose, noSolana } = props

    const { disconnectAsync } = useDisconnect()

    const handleConnect = async () => {
        await disconnectAsync()
    }
    useEffect(() => {
        handleConnect()
    }, [])

    return (
        <Modal className={cls.ConnectModal} isOpen={isOpen} onClose={onClose}>
            <h3>Choose one</h3>
            <div className={cls.cardsBlock}>
                <MMConnector />
                <WCConnector onClose={onClose} />
                {!noSolana && <SolanaConnector onClose={onClose} />}
            </div>
        </Modal>
    )
}
export default ConnectModal
