/* eslint-disable react/no-unescaped-entities */
import React from "react"
import { useWeb3Modal } from "@web3modal/wagmi/react"

import { Button } from "../../../shared/Button/Button"
import { Modal } from "../../../shared/Modal/Modal.jsx"
import cls from "./ConnectModal.module.scss"
import MM from "@/mm.svg"
import WC from "@/wc.svg"

import { ampli, track } from "../../../../helpers/amplitude"
import { Links } from "../../../../helpers/texts"
import { Link } from "../../../shared/Link/Link"

function WCConnector(props) {
    const { open } = useWeb3Modal()
    const { onClose } = props
    return (
        <Button
            className={cls.modalBtn}
            onClick={() => {
                track(ampli.connectModal, {
                    connector: "WalletConnect",
                    version: localStorage.getItem("userMainGroup")
                })
                onClose()
                open()
            }}
        >
            <img src={WC} width="80" height="80" data-img alt="WalletConnect" />
            <h4>WalletConnect</h4>
            <p>Use WalletConnect to connect</p>
            <Link arrow className={cls.link} href={false}>
                Connect
            </Link>
        </Button>
    )
}

function MMConnector() {
    return (
        <Button
            className={cls.modalBtn}
            onClick={() => {
                track(ampli.connectModal, { connector: "MetaMask", version: localStorage.getItem("userMainGroup") })
                window.open(Links.metamask, "_blank")
            }}
        >
            <img src={MM} width="80" height="80" data-img alt="MetaMask" />
            <h4>MetaMask</h4>
            <p>Connect to your MetaMask Wallet</p>
            <Link arrow className={cls.link} href={false}>
                Connect
            </Link>
        </Button>
    )
}

function ConnectModal(props) {
    const { isOpen, onClose } = props
    return (
        <Modal className={cls.ConnectModal} isOpen={isOpen} onClose={onClose}>
            <h3>Choose one</h3>
            <div className={cls.cardsBlock}>
                <MMConnector />
                <WCConnector onClose={onClose} />
            </div>
        </Modal>
    )
}
export default ConnectModal
