import React, { useEffect, useRef, lazy, Suspense, useState, useCallback, useMemo } from "react"
import { useWeb3ModalEvents } from "@web3modal/wagmi/react"

import { Slide, ToastContainer } from "react-toastify"
import { useAccount, useAccountEffect } from "wagmi"
import { useLocation, useNavigate } from "react-router"
import Hero from "~/components/widgets/MainPageWidgets/Hero/Hero.jsx"
import Loader from "~/components/shared/Loader/Loader.jsx"
import { mainconfig } from "../../../config"
import { ampli, track } from "../../../helpers/amplitude"
import { ShowModalButton } from "../../widgets/ShowModalButton/ShowModalButton"
import Header from "../../shared/Header/Header"
import { ToastCloseButton } from "../../shared/Toast/ToastCloseButton"

import { SuccessModalContext } from "../../../providers/SuccessModalContext/SuccessModalContext"
import { onPageLoad, toNFT } from "../../../helpers/helpers"

import { SolanaModalContext } from "../../../providers/SolanaModalContext/SolanaModalContext"
import { SolanaModal } from "../../widgets/MainPageWidgets/Prime/Solana/Solana"
import { SolanaToast } from "../../widgets/MainPageWidgets/Prime/SolanaToast/SolanaToast"
import { useGlobal } from "../../../providers/GlobalContext/GlobalContext"

const Footer = lazy(() => import("~/components/shared/Footer/Footer.jsx"))
const Roadmap = lazy(() => import("~/components/widgets/MainPageWidgets/Roadmap/Roadmap.jsx"))
const Utils = lazy(() => import("~/components/widgets/MainPageWidgets/Utilities/Utilities.jsx"))
const Team = lazy(() => import("~/components/widgets/MainPageWidgets/Team/Team.jsx"))
const Ecosystem = lazy(() => import("~/components/widgets/MainPageWidgets/Ecosystem/Ecosystem.jsx"))

const Tokenomic = lazy(() => import("~/components/widgets/MainPageWidgets/Tokenomic/Tokenomic.jsx"))
const Prime = lazy(() => import("~/components/widgets/MainPageWidgets/Prime/Prime"))
const MintSuccessModal = lazy(() => import("../../widgets/PremiumWidgets/MintSuccessModal/MintSuccessModal"))

document.addEventListener("readystatechange", () => {
    if (document.readyState === "complete") {
        onPageLoad()
    }
})

document.addEventListener("DOMContentLoaded", function () {
    const reachedPlaces = []
    setInterval(() => {
        const scrollTop = window.pageYOffset
        const windowHeight = window.innerHeight
        const scrollPlaceElements = document.querySelectorAll("[data-scroll-place-id]")
        scrollPlaceElements.forEach((item) => {
            const placeId = item.dataset.scrollPlaceId
            const placeTop = item.offsetTop

            if (placeTop < scrollTop + windowHeight / 2) {
                if (reachedPlaces.indexOf(placeId) === -1) {
                    reachedPlaces.push(placeId)

                    /* track(ampli.pageScroll, { reachedPlaces: JSON.stringify(reachedPlaces) }) */
                    track(ampli.pageScroll, { reachedPlaces: placeId, version: localStorage.getItem("userMainGroup") })
                }
            }
        })
    }, 500)
})

function Main() {
    const [showModalButton, setShowModalButton] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [shouldFocus, setShouldFocus] = useState(false)

    /* ---------------------------- REVEAL ANIMATION ---------------------------- */
    const revealElements = useRef(null)
    const refs = {
        HeroRef: useRef(null),
        RoadRef: useRef(null),
        EcoRef: useRef(null),
        NFTRef: useRef(null),
        TokenRef: useRef(null),
        LinkRef: useRef(null),
        MintRef: useRef(null)
    }

    const location = useLocation()
    const navigate = useNavigate()

    useEffect(() => {
        if (location?.state?.flag === "scrollToMint") {
            setTimeout(() => {
                toNFT()
                const newState = { ...location.state }
                delete newState.flag
                navigate(location.pathname, { state: newState, replace: true })
            }, 1000)
        }
    }, [location])
    const { clearGlobalState, globalState } = useGlobal()

    useAccountEffect({
        onDisconnect() {
            clearGlobalState()
            setShowModalButton(false)
            setShowModal(false)
        }
    })

    // eslint-disable-next-line consistent-return
    useEffect(() => {
        if (!mainconfig.isSafari) {
            const elements = revealElements.current.querySelectorAll("[data-reveal]")
            const options = {
                threshold: 0.1
            }
            const observer = new IntersectionObserver((entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add("animated")
                        observer.unobserve(entry.target)
                    }
                })
            }, options)
            elements.forEach((el) => observer.observe(el))

            return () => observer.disconnect()
        }
    }, [])

    const onCloseModal = useCallback(() => {
        setShowModal(false)
        track(ampli.successModalClose, { version: localStorage.getItem("userMainGroup") })
    }, [setShowModal])

    const { isConnected } = useAccount()

    const SuccessModalContextValue = useMemo(
        () => ({
            showModalButton,
            setShowModalButton,
            showModal,
            setShowModal,
            shouldFocus
        }),
        [showModalButton, setShowModalButton, showModal, setShowModal, shouldFocus]
    )

    const [isSolanaModal, setIsSolanaModal] = useState(false)
    const SolanaModalContextValue = useMemo(
        () => ({
            isSolanaModal,
            setIsSolanaModal
        }),
        [isSolanaModal, setIsSolanaModal]
    )

    const handleSolanaModalClose = useCallback(() => {
        setIsSolanaModal(false)
    }, [])

    const events = useWeb3ModalEvents()
    const [wasModalClosed, setWasModalClosed] = useState(false)

    useEffect(() => {
        if (events.data.event === "MODAL_CLOSE") {
            setWasModalClosed(true)
        }
    }, [events.data.event])

    return (
        <SuccessModalContext.Provider value={SuccessModalContextValue}>
            <SolanaModalContext.Provider value={SolanaModalContextValue}>
                <Header refs={refs} />
                <div id="content-wrap" className={mainconfig.isMobile ? "safari" : ""} ref={revealElements}>
                    <main className="main-sections">
                        <Hero ref={refs.HeroRef} NFTRef={refs.NFTRef} />
                        <Suspense fallback={<Loader />}>
                            <Ecosystem ref={refs.EcoRef} LinkRef={refs.LinkRef} />
                        </Suspense>
                        <Suspense fallback={<Loader />}>
                            <Team />
                        </Suspense>
                        <Suspense fallback={<Loader />}>
                            <Tokenomic ref={refs.TokenRef} />
                        </Suspense>
                        <Suspense fallback={<Loader />}>
                            <Utils />
                        </Suspense>
                        <Suspense fallback={<Loader />}>
                            <Prime ref={refs.NFTRef} MintRef={refs.MintRef} />
                        </Suspense>
                        <Suspense fallback={<Loader />}>
                            <Roadmap ref={refs.RoadRef} LinkRef={refs.LinkRef} />
                        </Suspense>
                    </main>
                    <Suspense fallback={null}>
                        <Footer />
                    </Suspense>
                    <ToastContainer
                        autoClose={6000}
                        className="customToast"
                        closeButton={ToastCloseButton}
                        position="bottom-right"
                        hideProgressBar
                        transition={Slide}
                        pauseOnFocusLoss={false}
                    />
                    {showModalButton && (
                        <ShowModalButton
                            onClick={() => {
                                track(ampli.modalButton, { version: localStorage.getItem("userMainGroup") })
                                setShowModal(true)
                            }}
                        />
                    )}
                    {showModal && (
                        <Suspense fallback={null}>
                            <MintSuccessModal
                                isOpen={showModal}
                                onClose={onCloseModal}
                                setShouldFocus={setShouldFocus}
                            />
                        </Suspense>
                    )}
                </div>
                {wasModalClosed &&
                    events.data.properties?.connected === false &&
                    !showModalButton &&
                    !mainconfig.isMobile &&
                    !isConnected && <SolanaToast />}

                {isSolanaModal && <SolanaModal onClose={handleSolanaModalClose} isOpen={isSolanaModal} />}
            </SolanaModalContext.Provider>
        </SuccessModalContext.Provider>
    )
}

export default Main
