import * as amplitude from "@amplitude/analytics-browser"

export function track(eventName, eventParams) {
    if (eventParams) {
        // console.log(eventName, eventParams)
        amplitude.track(eventName, eventParams)
    } else {
        amplitude.track(eventName)
    }
}

export const ampliEvents = {
    test: "TEST",
    ethConnect: "eth_connect",
    ethConnected: "eth_connected",
    ethConnectError: "eth_connect_error",
    cryptoSelect: "crypto_select",
    cryptoSelectButton: "crypto_select_button",
    scrollPlace: "scroll_place",
    purchase: "purchase",
    purchaseSuccess: "purchase_success",
    purchaseFailure: "purchase_failure",
    purchaseTx: "purchase_transaction",

    connectWalletButton: "connect_wallet_button_click",
    connectWalletError: "connect_wallet_error",
    connectWalletSuccess: "connect_wallet_success",
    purchaseSubmit: "mint_submit",
    purchaseError: "purchase_error",
    purchaseInput: "mint_input_active",
    etherscanClick: "etherscan_click"
}

export const ampli = {
    navClick: "NavigationClick",
    solanaDegenClick: "SolanaButtonClick",
    solanaModalClicks: "SolanaModalClick",
    testGroup: "PurchaseTestUserGroup",
    walletSuccess: "Successful Wallet Connect",
    walletSuccessBalance: "WalletConnectBalanceSuccess",
    walletError: "Error Wallet Connect",
    chain: "Chain Switch",
    pageLoad: "Page Load",
    pageScroll: "Page Scrolled",
    connectModal: "Mobile Connect Modal",
    modalButton: "GeneralDiscordButtonClick",
    walletHeader: "HeaderConnectWalletClick",
    ecoClick: "EcosystemClick",
    // valuesClick: "ValuesClick",
    tokenClick: "TokenomicClick",
    utilClick: "UtilitiesClick",
    voucherConnectWalletClick: "MintConnectWalletClick",
    voucherMintClick: "MintBlockClick",
    mintDisabled: "MintDisabledClick",
    footerClick: "FooterClick",
    join: "Reserve $TOON Click",
    mintError: "MintError",
    mintSuccess: "MintSuccess",
    successModal: "ModalMintSucces",
    successModalClicks: "ModalMintSuccessClick",
    networkChanged: "NetworkChanged",
    сhangeNetworkClick: "ChangeNetworkClick",
    freeMintCheckboxClick: "FreeMintCheckboxClick",
    successModalClose: "ModalMintSuccessClose",
    mintTabClick: "MintTabClick",
    userGroup: "ModalUserGroup",
    usdButton: "usdBalanceClick",
    yourAmount: "YourAmountClick",
    minAmountGroup: "MinAmountABGroup",
    lowBalance: "LowBalanceInput",
    signed: "CustomizeSignSuccess",
    notsigned: "CustomizeSignError",
    closeSignModal: "CloseSignModal",
    closeModal: "Connect modal closed",
    balance: "CustomizeUserBalance",
    uploadClick: "CustomizeUploadClick",
    uploadSuccess: "CustomizeUploadSuccess",
    uploadFail: "CustomizeUploadFail",
    choiceScreen: "ChoiceScreenShowed",
    choiceSubmit: "CustomizeChoiceSubmit",
    choiceMade: "CustomizeChoiceMade",
    balanceCustomize: "CustomizeBalanceSuccess"
}
