import React from "react"
import { classNames } from "~/tools/classNames"

import cls from "./Link.module.scss"

// <div className={`flex ${className} ${cls.wrapper}`}></div>

export function Link(props) {
    const { href, className, children, arrow, target, onClick, blue, ...otherProps } = props
    const mods = {
        [cls.arrow]: arrow,
        [cls.blue]: blue
    }
    return href ? (
        <a
            href={href}
            target={target || "_blank"}
            rel="noreferrer"
            className={classNames(cls.link, mods, [className])}
            onClick={onClick}
            {...otherProps}
        >
            {arrow ? (
                <svg
                    className={cls.arrow}
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    preserveAspectRatio="none"
                    viewBox="0 0 24 24"
                >
                    <g
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        clipPath="url(#a)"
                    >
                        <path d="M3.8 12h16.4M13.5 18.8l6.8-6.8-6.8-6.8" />
                    </g>
                    <defs>
                        <clipPath id="a">
                            <path fill="currentColor" d="M24 24H0V0h24z" />
                        </clipPath>
                    </defs>
                </svg>
            ) : (
                <>{children}</>
            )}
        </a>
    ) : (
        <a className={classNames(cls.link, mods, [className])} {...otherProps}>
            <svg
                className={cls.arrow}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                preserveAspectRatio="none"
                viewBox="0 0 24 24"
            >
                <g
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    clipPath="url(#a)"
                >
                    <path d="M3.8 12h16.4M13.5 18.8l6.8-6.8-6.8-6.8" />
                </g>
                <defs>
                    <clipPath id="a">
                        <path fill="currentColor" d="M24 24H0V0h24z" />
                    </clipPath>
                </defs>
            </svg>
        </a>
    )
}
