import React, { useCallback, useEffect, useRef, useState } from "react"
import { classNames } from "~/tools/classNames.js"
import CrossImg from "@/UI/icons/cross.svg"
import { Portal } from "../Portal/Portal"
import cls from "./Modal.module.scss"

const ANIMATION_DELAY = 300

function Cross({ onClick }) {
    return (
        <div className={cls.cross} onClick={onClick} data-cross>
            <img src={CrossImg} alt="close" />
        </div>
    )
}

export function Modal(props) {
    const { className, children, isOpen, onClose, overflow } = props
    const [isClosing, setIsClosing] = useState(false)
    const timerRef = useRef()
    const closeHandler = useCallback(() => {
        if (onClose) {
            setIsClosing(true)
            timerRef.current = setTimeout(() => {
                onClose()
                setIsClosing(false)
            }, ANIMATION_DELAY)
        }
    }, [onClose])
    const onKeyDown = useCallback(
        (e) => {
            if (e.key === "Escape") {
                closeHandler()
            }
        },
        [closeHandler]
    )

    useEffect(() => {
        if (isOpen) {
            window.addEventListener("keydown", onKeyDown)
            document.body.style.overflow = "hidden"
        }

        return () => {
            document.body.style.overflow = ""

            document.body.style.overflowX = "hidden"
            clearTimeout(timerRef.current)
            window.removeEventListener("keydown", onKeyDown)
        }
    }, [isOpen, onKeyDown])

    const onContentClick = (e) => {
        e.stopPropagation()
    }

    const mods = {
        [cls.opened]: isOpen,
        [cls.isClosing]: isClosing,
        [cls.overflow]: overflow
    }

    return (
        <Portal>
            <div className={classNames(cls.Modal, mods, [className])}>
                <div className={cls.overlay} onClick={closeHandler}>
                    <div className={cls.wrapper}>
                        <Cross onClick={closeHandler} />
                        <div className={cls.content} onClick={onContentClick} data-modal-content>
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </Portal>
    )
}
