/* eslint-disable react/no-unescaped-entities */
import { useCallback, useEffect, useState } from "react"
import { useNavigate } from "react-router"
import { Button } from "~/components/shared/Button/Button"
import { Modal } from "~/components/shared/Modal/Modal.jsx"
import cls from "./MintSuccessModal.module.scss"

import Dsc1 from "@/modalDsc1.webp"
import Dsc2 from "@/modalDsc2.webp"
import Dsc3 from "@/modalDsc3.webp"
import Dsc4 from "@/modalDsc4.webp"
import Cards from "@/NFT/prime.webp"

import DiscordIcon from "@/UI/icons/discord.svg"
import DiscordPurple from "@/UI/icons/discord_purple.svg"

import { ampli, track } from "~/helpers/amplitude"
import { Links } from "~/helpers/texts"
import { ButtonSize } from "../../../shared/Button/Button"
import { Link } from "../../../shared/Link/Link"
import { useGlobal } from "../../../../providers/GlobalContext/GlobalContext"

export function JoinButton() {
    return (
        <Button
            link
            href={Links.discord}
            onClick={() =>
                track(ampli.successModalClicks, {
                    source: "join us button",
                    version: localStorage.getItem("userMainGroup"),
                    ab_group: "default"
                })
            }
            className={cls.discordButton}
            size={ButtonSize.full}
            // color={noColor ? ButtonColor.none : !defaultGroup ? ButtonColor.none : undefined}
        >
            <img src={DiscordIcon} alt="x" width="18" height="18" />
            <span>Join Us On Discord</span>
        </Button>
    )
}

const images = [Dsc1, Dsc2, Dsc3, Dsc4]
function MintSuccessModal(props) {
    const { isOpen, onClose } = props

    const navigate = useNavigate()

    const [currentImageIndex, setCurrentImageIndex] = useState(0)

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length)
        }, 1500)

        return () => clearInterval(intervalId)
    }, [])

    const { globalState, updateMinted } = useGlobal()

    const handleCustomize = useCallback(() => {
        updateMinted()
        track(ampli.successModalClicks, {
            source: "customize",
            ab_group: "custom",
            version: localStorage.getItem("userMainGroup")
        })
        navigate("/customize")
    }, [])

    return (
        <Modal
            className={`${cls.MintSuccess} ${localStorage.userGroup === "custom" ? cls.default : cls.default}`}
            isOpen={isOpen}
            onClose={onClose}
        >
            {localStorage.userGroup === "custom" ? (
                <>
                    <h4>Congratulations on completing your purchase!</h4>
                    <h3>Congratulations on completing your purchase!</h3>
                    <p>
                        {globalState.taskID
                            ? "We’re about to convert your new crypto avatar into an NFT. Finalize the transaction and your visual will be updated."
                            : "Your exclusive privilege to customize your ToON NFT awaits — try this out!"}
                    </p>
                    <div className={cls.imageWrapper}>
                        <div className={cls.imageContainer}>
                            {images.map((image, index) => (
                                <img
                                    key={image}
                                    src={image}
                                    alt={`Slide ${index}`}
                                    className={`${cls.fade} ${index === currentImageIndex ? cls.visible : ""}`}
                                />
                            ))}
                        </div>
                    </div>
                    <Button className={cls.button} onClick={handleCustomize}>
                        {globalState.taskID ? "Update" : "Customize"}
                    </Button>
                    <div className={cls.card}>
                        <img src={DiscordPurple} alt="discord" width="48" height="48" className={cls.icon} />
                        <span>
                            Afterward, show off your unique NFT and connect with others in our exclusive community.{" "}
                            <b>Click to join!</b>
                        </span>
                        <Link
                            arrow
                            href={Links.discord}
                            onClick={() =>
                                track(ampli.successModalClicks, {
                                    source: "discord",
                                    ab_group: "custom",
                                    version: localStorage.getItem("userMainGroup")
                                })
                            }
                        />
                    </div>
                </>
            ) : (
                <>
                    <img src={Cards} className={cls.cards} alt="prime nft" />
                    <h3 className={cls.h3}>
                        Purchase complete!
                        <br />
                        You'll receive your tokens once the public sale starts. 
                    </h3>

                    <h5>Stay tuned & join our exclusive token holders community.</h5>
                    <JoinButton />
                </>
            )}
        </Modal>
    )
}
export default MintSuccessModal
