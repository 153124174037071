import React from "react"
import Discord from "@/UI/icons/discord_purple.svg"
import cls from "./ShowModalButton.module.scss"
import { Button } from "../../shared/Button/Button"


export function ShowModalButton({ onClick }) {
    return (
        <div className={cls.fixed} onClick={onClick}>
            <Button discordMain className={cls.modalButton}>
                <div className="sh" />
                <img className={cls.image} src={Discord} alt="Discord" />
            </Button>
        </div>
    )
}
