import { useCallback, useContext, useState } from "react"
import { SolanaModalContext } from "../../../../../providers/SolanaModalContext/SolanaModalContext"
import { classNames } from "../../../../../tools/classNames"
import cls from "./SolanaToast.module.scss"
import Solana from "@/UI/icons/solana-icon.svg"
import Ton from "@/UI/icons/ton.svg"
import { track } from "../../../../../helpers/amplitude"

export const SolanaToast = ({ className }) => {
    const { setIsSolanaModal } = useContext(SolanaModalContext)
    const [isCompressed, setIsCompressed] = useState(false)
    const handleClick = useCallback(() => {
        track("SolanaToastClick", {
            version: localStorage.getItem("userMainGroup")
        })
        setIsSolanaModal(true)
        setTimeout(() => {
            setIsCompressed(true)
        }, 1000)
    }, [])

    const mods = { [cls.compressed]: isCompressed }
    return (
        <div className={classNames(cls.SolanaToast, mods, [className])} onClick={handleClick}>
            <div className={cls.icons}>
                <img src={Solana} width="32" height="32" className={cls.sol} alt="solana" />
                <img src={Ton} width="32" height="32" className={cls.ton} alt="ton" />
            </div>
            {isCompressed ? (
                <span>
                    <b>Reserve $TOON</b>
                </span>
            ) : (
                <span>
                    Reserve $TOON on <b>Solana</b> or <b>TON</b>
                </span>
            )}
            {!isCompressed && (
                <button type="button" className={cls.button} onClick={handleClick}>
                    Reserve
                </button>
            )}
        </div>
    )
}
