import React from "react"

export const CONNECT_ERROR = "Oops! Something went wrong. Please try again."

export const PURCHASE_ERRORS = {
    32000: "Insufficient funds for this transaction. Kindly add more to your account and try again.",
    4001: "Transaction signature declined by user.",
    general: "Apologies, something didn't go as planned. Please retry."
}

export const WALLET_SUCCESS = "Your wallet successfully connected!"
export const CHECK_WALLET = (
    <>
        Check your <b>wallet app</b> to sign the transaction.
    </>
)

export const Links = {
    miniapp: "https://t.me/toon_nation_bot",
    gitbook: {
        main: "https://toon-org.gitbook.io/toon-gitbook-1/",
        emission: "https://toon-org.gitbook.io/toon-gitbook-1/tokenomics#token-emission-and-total-supply",
        distribution: "https://toon-org.gitbook.io/toon-gitbook-1/tokenomics#token-distribution",
        growth: "https://toon-org.gitbook.io/toon-gitbook-1/tokenomics#continuous-fuel-for-project-growth",
        governance: "https://toon-org.gitbook.io/toon-gitbook-1/governance",
        methods:
            "https://toon-org.gitbook.io/toon-gitbook-1/toon-team-and-viral-methods#unleashing-web3-vitality-through-user-friendly-steps",
        charity: "https://toon-org.gitbook.io/toon-gitbook-1/charity"
    },
    apple: "https://apps.apple.com/app/id1635754347",
    google: "https://play.google.com/store/apps/details?id=com.toonlabs.tooncoin",
    bitcoin: "https://t.me/BitcoinArtBot",
    twitter: "https://twitter.com/tooncoin",
    discord: "https://discord.gg/QmDCZgq9Tm",
    privacy: "https://get.toon.org/privacy.html",
    metamask: "https://metamask.app.link/dapp/get.toon.org/",
    zealy: "https://zealy.io/c/toonorg/questboard",
    medium: "https://medium.com/@toon_org",
    chat: "https://t.me/toon_support",
    chatPremium: "https://t.me/toon_support_premium",
    mail: "mailto:support@toon.org"
}

const minAmountGroup = localStorage.getItem("minAmountGroup")

export const TooltipText = {
    selectBasic: "If you don‘t want to make a deposit, select <b>Basic Pass</b>",
    checkBox: "Please click on checkbox <b>to mint NFT</b>",
    enterAmount: "Please enter an amount <b>to deposit</b>",
    befirst:
        "Once the public sale starts, those who bought Prime Pass will have their $TOON distributed through the network the vouchers were purchased",
    receive:
        "The exchange rate is based on the price of the token minting on the contract at the time of the public sale.",
    validation: `Please enter number bigger than <b>${minAmountGroup}$</b>`,
    funds: "Insufficient funds"
}
