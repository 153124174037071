import React, { useCallback, useEffect, useState } from "react"

import cls from "./Solana.module.scss"

import Solana from "@/UI/icons/solana-icon.svg"
import Ton from "@/UI/icons/ton.svg"

import Copy from "@/UI/icons/copyW.svg"
import Copied from "@/UI/icons/copied.svg"
import DiscordIcon from "@/UI/icons/discord.svg"
import { Button, ButtonColor, ButtonSize } from "../../../../shared/Button/Button"
import { ampli, track } from "../../../../../helpers/amplitude"
import { Modal } from "../../../../shared/Modal/Modal"
import { Links } from "../../../../../helpers/texts"

export const SolanaButton = ({ onClick }) => {
    return (
        <>
            <Button className={cls.SolanaButton} color={ButtonColor.blue} onClick={onClick}>
                <span>
                    <b>Solana & TON</b> presale
                </span>
                <div className={cls.icons}>
                    <img src={Solana} width="32" height="32" alt="solana" />
                    <img src={Ton} width="32" height="32" alt="ton" />
                </div>
            </Button>
            <div className={cls.SolanaMbl} onClick={onClick}>
                <div className={cls.icons}>
                    <img src={Solana} width="20" height="20" alt="solana" />
                    <img src={Ton} width="20" height="20" alt="ton" />
                </div>

                <div className={cls.text}>Solana & TON Presale</div>
            </div>
        </>
    )
}

export const SolanaModal = ({ isOpen, onClose }) => {
    const [isCopied, setIsCopied] = useState(false)

    useEffect(() => {
        setIsCopied(false)
    }, [isOpen])

    const [activeItem, setActiveItem] = useState("Solana")
    const handleCopy = async () => {
        try {
            await navigator.clipboard.writeText(
                activeItem === "Solana"
                    ? "5Vqnwrx3FyzsnrWFJxiePgBtt5gYUiweU6SHH1HmiPya"
                    : "UQCzTXw_AikwLca-rDgUIXHFBM3JP_QqRLg35iYHq4qpyDWY"
            )
            setIsCopied(true)
            track(ampli.solanaModalClicks, {
                source: "copy button",
                network: activeItem,
                version: localStorage.getItem("userMainGroup")
            })
        } catch (err) {
            console.error("Failed to copy text: ", err)
        }
    }
    const handleChooseSolana = useCallback(() => {
        setIsCopied(false)
        setActiveItem("Solana")
    }, [])
    const handleChooseTON = useCallback(() => {
        track("TonTabClick", {
            version: localStorage.getItem("userMainGroup")
        })
        setIsCopied(false)
        setActiveItem("TON")
    }, [])

    return (
        <Modal className={cls.SolanaModal} isOpen={isOpen} onClose={onClose}>
            <div className={cls.slider}>
                <div
                    className={`${cls.sliderItem} ${activeItem === "Solana" ? cls.active : ""}`}
                    onClick={handleChooseSolana}
                >
                    <img src={Solana} width="32" height="32" alt="solana" />
                    <span>Solana</span>
                </div>
                <div
                    onClick={handleChooseTON}
                    className={`${cls.sliderItem} ${activeItem === "TON" ? cls.active : ""}`}
                >
                    <img src={Ton} width="32" height="32" alt="ton" />
                    <span>TON</span>
                </div>
            </div>
            <div className={cls.scrollInfo}>
                <h3>
                    {activeItem === "Solana" ? "Solana" : "TON"} support coming soon! But you can already reserve $TOON
                    with
                    {activeItem === "Solana" ? " SOL" : " TON"}.
                </h3>
                <h4>
                    {activeItem === "Solana" ? "Solana" : "TON"} support coming soon! But you can already reserve $TOON
                    with
                    {activeItem === "Solana" ? " SOL" : " TON"}.
                </h4>
                <div className={cls.list}>
                    <div className={cls.item}>
                        <div className={cls.number}>1</div>
                        <div className={cls.info1}>
                            <span>
                                Send a minimum of <b>{activeItem === "Solana" ? "0.3 SOL" : "10 TON"}</b> to
                            </span>
                            <div className={cls.copyAddress} onClick={handleCopy}>
                                <span>
                                    {activeItem === "Solana"
                                        ? "5Vqnwrx3FyzsnrWFJxiePgBtt5gYUiweU6SHH1HmiPya"
                                        : "UQCzTXw_AikwLca-rDgUIXHFBM3JP_QqRLg35iYHq4qpyDWY"}
                                </span>
                                <img src={isCopied ? Copied : Copy} width="24" height="24" alt="copy icon" />
                            </div>
                            <span>
                                from your <b>non-custodial wallet</b> that’s not linked to an <b>exchange</b>.
                            </span>
                        </div>
                    </div>
                    <div className={cls.item}>
                        <div className={cls.number}>2</div>
                        <div className={cls.info}>
                            Once {activeItem === "Solana" ? "Solana's" : "TON's"} support is enabled, we will send an
                            NFT confirming the deposit to your address.
                        </div>
                    </div>
                    <div className={cls.item}>
                        <div className={cls.number}>3</div>
                        <div className={cls.info}>
                            When public sale starts, you'll get $TOON at the best exchange rate.
                        </div>
                    </div>
                    <div className={cls.item}>
                        <div className={cls.number}>4</div>
                        <div className={cls.info}>Stay tuned for Discord announcements.</div>
                    </div>
                </div>
                <Button
                    link
                    href={Links.discord}
                    onClick={() =>
                        track(ampli.solanaModalClicks, {
                            source: "join discord button",
                            network: activeItem,
                            version: localStorage.getItem("userMainGroup")
                        })
                    }
                    className={cls.discordButton}
                    size={ButtonSize.full}
                >
                    <img src={DiscordIcon} alt="discord" width="18" height="18" />
                    <span>Join Our Discord</span>
                </Button>
            </div>
        </Modal>
    )
}
